<template>
  <div style="font-size: 16px;">
    <div class="shopStore-container">
      <div class="shopStore-head">
        <!-- 返回按钮 -->
        <div @click="handleBack()" class="shopStore-head_btn">
          <img class="shopStore-head_btn_icon" src="@/assets/shop/icon/shopIndex.jpg" alt="">
          <span class="shopStore-head_btn_text">{{ $t('shop_detail_backBtn') }}</span>
          </div>
        <!-- <img @click="handleBack()" v-else class="shopStore-head-back" src="@/assets/common/back-icon.jpg" alt=""> -->
        <img class="shopStore-head-changeLong" @click="navTranslate" :src="languageType == 'en' ?  require('@/assets/shop/icon/langCn.png') : require('@/assets/shop/icon/langEn.png')" alt="">
      </div>
      <div class="store">
        <!-- 邮件片单 跳转进 详情页 -->
        <div class="store-nav"
            @mouseleave="handleMenuContainerMouseLeave()"
            v-show="!isFilmListExpire && !isFilmListFailed && isFilmListLoad">
          <!-- <div class="footer-logo"
              @click="jumpInterlibHome">
            <img src="@/assets/store/store_interlib_logo.png"
                alt="" />
          </div> -->

          <img src="@/assets/store/store_nav_top.png"
              class="scroll-up-btn"
              @click="scrollPage(-1)"
              v-show="showScrollUp"
              alt=""><!-- 列表顶部的按钮 -->

          <div class="store-left-nav"
              :style="{ justifyContent: justifyContent }"
              ref="storeLeftContainer"
              @scroll="handleScroll">
            <div class="menu-container">
              <div class="menu-item-bg"
                  :style="{top: menuItemBgTop}"></div>
              <div class="menu-item-hovered-bg"
                  :style="{top: menuItemHoveredBgTop}"
                  v-show="isHoveredBgVisible"></div>
              <div v-for="(item, index) in filmListData"
                  :key="index"
                  class="menu-item"
                  :class="{'is-selected': item.isSelect === true, 'is-hovered': hoverItemIndex === index && item.isSelect === false}"
                  @click="handleItemClick(item, index)"
                  @mouseover="handleItemMouseOver(item, index)"
                  :ref="`menuItem-${index}`">
                <div class="content-container"
                    v-html="item.code"></div>
                <span v-show="!item.isSelect && hoverItemIndex !== index">{{ item.projectEnName }}</span>
              </div>
            </div>
          </div>

          <img src="@/assets/store/store_nav_bottom.png"
              class="scroll-down-btn"
              @click="scrollPage(1)"
              v-show="showScrollDown"
              alt=""><!-- 列表底部的按钮 -->
        </div>

        <div class="store-container"
            v-show="!isFilmListExpire  && !isFilmListFailed  && isFilmListLoad">
          <storeContainerPage :issuerInfo="issuerInfo"></storeContainerPage>
        </div>

        <div class="store-film-expire"
            v-show="isFilmListExpire  && isFilmListLoad">
          {{ $t('store_film_expire_tips')}}
        </div>

        <div class="store-film-expire"
            v-show="isFilmListFailed  && isFilmListLoad">
          {{ $t('store_film_expire_tips_2')}}
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import StoreContainerPage from '@/views/shopStore/StoreContainerPage.vue'
import { storeFilmListApi } from '@/api/storeApi.js'
import { recordStoreLogActionApi } from '@/api/userLogApi.js'


export default {
  name: 'shopStore',
  components: {
    storeContainerPage: StoreContainerPage
  },

  data () {
    return {
      languageType: '',
      filmListData: [],
      justifyContent: 'center',
      showScrollUp: false,
      showScrollDown: true,
      // selectedItemIndex: undefined,
      hoverItemIndex: null,
      menuItemBgTop: '30px',
      menuItemBgTopData: 30,
      menuItemHeight: 140,
      menuItemHoveredBgTop: null, // 新增属性，存储menu-item-hovered-bg的top值
      isHoveredBgVisible: false, // 新增属性，控制menu-item-hovered-bg的可见性
      hoverTransitionTimeout: null, // 用于延迟动画的定时器
      selectedItemInterlibContentId: undefined,
      issuerInfo: {},
      shopId: '', // 店铺id
      isFilmListExpire: true,
      isFilmListLoad: false,
      isFilmListFailed: false,
      deviceType: 0,//0 :笔记本 1：PC 2：手机
    }
  },
  beforeDestroy () {
    // 其他清理工作不变
    clearTimeout(this.hoverTransitionTimeout);
  },
  created() {
    

    this.reloadLocationLanguageData()
    let languageLinkText = ''
    if (['zh','cn'].includes(this.languageType) ) {
      languageLinkText = 'cn'
    } else {
      languageLinkText = 'en'
    }
    // deviceType: '0',//0 :笔记本 1：PC 2：手机
    var mediaQueryPC = window.matchMedia('(min-width: 1920px)');
    if (mediaQueryPC.matches) {
      this.deviceType = 1;
      this.menuItemHeight = 140
    } else {
      var mediaQuery = window.matchMedia('(max-width: 750px)');
      if (mediaQuery.matches) {
        this.deviceType = 2;
        this.menuItemHeight = 72
        console.error(1111,this.$route, location.href)
        console.log(this.$route)
        const dramaId = this.getUrlKey('click_interlib_content_id')
        const mailFilmId = this.getUrlKey('mail_film_id')
      // location.href=`http://localhost:8085/mobile${this.$route.fullPath}`
      // location.href=`http://${window.location.hostname}:${window.location.port}/mobile${this.$route.fullPath}`
      location.href=`http://${window.location.hostname}:${window.location.port}/mobile/${languageLinkText}/shopStore?mail_film_id=${this.$route.query.mail_film_id || mailFilmId ||''}&click_interlib_content_id=${this.$route.query.click_interlib_content_id || dramaId ||''}&type=2`
      } else {
        this.deviceType = 0;
        this.menuItemHeight = 140
      }
    }

    // 获取设备类型
    console.log('this.deviceType:', this.deviceType);
  },
  mounted () {
    this.fetchStoreFilmListData()
    // this.fetchStoreRecordLogData()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkContainerHeight);
  },
  methods: {

    // 获取url参数
    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || ''
    },
    //中英文切换
    navTranslate () {
      console.log("中英文切换:", this.languageType);
      if (this.languageType == 'en') {
        localStorage.setItem('locationLanguageType', 'zh')
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
      const location_href = window.location.href;
      if (location_href.includes('/cn')) {
        let modifiedHref = location_href.replace('/cn', '/' + 'en');
        window.location.href = modifiedHref;
      } else {
        let modifiedHref = location_href.replace('/en', '/' + 'cn');
        window.location.href = modifiedHref;
      }
    },

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    // 返回按钮
    handleBack() {
      console.log('返回')
      // console.log('window.location', window.location)
        // this.$router.push({
        //   name: 'language-shop',
        //   query: {
        //     type: 3,
        //     shop_id: this.shopId,
        //   }
        // })
        let languageLinkText = ''
        if (['zh','cn'].includes(this.languageType) ) {
          languageLinkText = 'cn'
        } else {
          languageLinkText = 'en'
        }
        location.href=`http://${window.location.hostname}:${window.location.port}/${languageLinkText}/shop?type=3&shop_id=${this.shopId}`
    },
    checkContainerHeight () {
      const container = this.$refs.storeLeftContainer;
      const rect = container.getBoundingClientRect();
      const heightDifference = container.scrollHeight - rect.height;
      if (heightDifference > 0) {
        this.justifyContent = 'flex-start';
        this.menuItemBgTopData = 30
      } else {
        this.justifyContent = 'center';
        if (this.deviceType === 1) {
          this.menuItemBgTopData = 138// PC
        } else {
          this.menuItemBgTopData = 82// 笔记本
        }

        // 确保容器和第一个菜单项都已被正确渲染
        const firstMenuItem = this.$refs['menuItem-0'][0]; // 假设第一个菜单项的ref为'menuItem-0'

        if (container && firstMenuItem) {
          // 获取第一个菜单项的位置信息
          const menuItemRect = firstMenuItem.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();

          // 计算距离顶部的距离
          const topDistance = menuItemRect.top - containerRect.top;

          const firstItem = this.filmListData[0]
          if (firstItem.isSelect) {
            this.menuItemBgTopData = topDistance + 15;
          } else {
            this.menuItemBgTopData = topDistance + 8;
          }
          console.log("topDistance:", topDistance);
        }
      }
      this.handleScroll(); // 初始化按钮显示
    },
    scrollPage (direction) {
      const container = this.$refs.storeLeftContainer;
      container.scrollBy({ top: direction * container.clientHeight, behavior: 'smooth' });
    },
    handleScroll () {
      const container = this.$refs.storeLeftContainer;
      this.showScrollUp = container.scrollTop > 0;
      // 增加一个小的容忍值来确保即使有小的计算误差也能正确判断是否滚动到底部
      const atBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 1;
      this.showScrollDown = !atBottom;
    },
    handleItemClick (item, index) {
      if (item.isSelect) return

      // this.fetchStoreRecordEndLogData()

      this.filmListData.forEach(tempItem => {
        tempItem.isSelect = false;
      });

      this.$nextTick(() => {
        // 设置当前点击项的isSelect为true
        item.isSelect = true;

        console.log('handleItemClick.filmListData:', this.filmListData);

        this.selectedItemInterlibContentId = item.interlibContentId;
        // this.selectedItemIndex = index
        this.hoverItemIndex = null;
        this.isHoveredBgVisible = false;
        localStorage.setItem('locationStoreClickInterlibContentId', item.interlibContentId ? item.interlibContentId : '')

        console.log("selectedItemInterlibContentId:", this.selectedItemInterlibContentId);
        // 计算menu-item-bg新的top值
        this.calculateMenuItemBgPosition(index);
        // this.fetchStoreRecordLogData()
      });
    },
    calculateMenuItemBgPosition (index) {
      const newTop = index * this.menuItemHeight + this.menuItemBgTopData;
      this.menuItemBgTop = `${newTop}px`;
    },
    handleItemMouseOver (item, index) {
      if (item.isSelect) return
      if (this.hoverItemIndex !== index && this.menuItemHoveredBgTop === null) {
        clearTimeout(this.hoverTransitionTimeout);
        this.calculateMenuItemHoveredBgPosition(index);
        this.hoverItemIndex = index;
      } else {
        clearTimeout(this.hoverTransitionTimeout);
        this.hoverTransitionTimeout = setTimeout(() => {
          this.calculateMenuItemHoveredBgPosition(index);
          this.hoverItemIndex = index;
        }, 50);
      }
      this.isHoveredBgVisible = true;
    },
    handleItemMouseLeave (index) {
      if (this.hoverItemIndex === index) {
        this.hoverItemIndex = null;
      }
      this.isHoveredBgVisible = false;
    },
    handleMenuContainerMouseLeave () {
      this.hoverTransitionTimeout = setTimeout(() => {
        this.hoverItemIndex = null;
        this.isHoveredBgVisible = false;
      }, 50);
    },
    calculateMenuItemHoveredBgPosition (index) {
      const newTop = index * this.menuItemHeight + this.menuItemBgTopData;
      this.menuItemHoveredBgTop = `${newTop}px`;
    },
    //获取店铺片单列表数据
    fetchStoreFilmListData () {
      const locationStoreMailFilmId = localStorage.getItem('locationStoreMailFilmId')
      const data = {
        mailFilmId: locationStoreMailFilmId,
      }
      console.log("获取店铺片单列表数据-参数：", data);

      storeFilmListApi(data)
        .then((res) => {
          this.isFilmListLoad = true
          console.log("res.data.code:",);
          if (res.data.code === 200) {
            this.isFilmListFailed = false;

            if (!res.data.data && res.data.message === 'Expire') {
              this.isFilmListExpire = true;
              localStorage.setItem('locationStoreClickInterlibContentId', 0)
              console.log('res.data.code === 20012');
              const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')

              console.log("StoreContainerPage created:", locationStoreClickInterlibContentId);
            } else {
              this.isFilmListExpire = false;

              const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
              console.log('locationStoreClickInterlibContentId===::::', locationStoreClickInterlibContentId)

              const optimizedFilmList = res.data.data.imgTextFilmList.map(film => {
                const filmIdAsString = String(film.interlibContentId);
                const locationStoreIdAsString = String(locationStoreClickInterlibContentId);
                film.isSelect = filmIdAsString === locationStoreIdAsString;
                console.log('filmIdAsString', filmIdAsString);
                console.log('filmIdAsString', locationStoreIdAsString);

                return film;
              });

              this.filmListData = optimizedFilmList;


              // 查找匹配的索引
              const matchingIndex = res.data.data.imgTextFilmList.findIndex(film => {
                // 将 interlibContentId 转换为字符串进行比较
                const filmIdAsString = String(film.interlibContentId);
                const locationStoreIdAsString = String(locationStoreClickInterlibContentId);
                return filmIdAsString === locationStoreIdAsString;
              });

              // if(matchingIndex<0) 
              // 当没有具体单剧id时，默认选中第一个
              if(!locationStoreClickInterlibContentId) {
                this.filmListData[0].isSelect = true
                matchingIndex = 0
                localStorage.setItem('locationStoreClickInterlibContentId', this.filmListData[0].interlibContentId)
              }
              
              console.log('this.filmListData:', this.filmListData);

              this.issuerInfo = res.data.data.issuerInfo
              console.log('issuerInfo:', this.issuerInfo);
              this.shopId = res.data.data.shopId
              console.log('shopId:', this.shopId);

              this.$nextTick(() => {
                this.checkContainerHeight();
                window.addEventListener('resize', this.checkContainerHeight);
                this.hoverItemIndex = null;
                this.isHoveredBgVisible = false;

                // 计算menu-item-bg新的top值
                this.calculateMenuItemBgPosition(matchingIndex);

                // 滚动逻辑
                this.scrollToItem(matchingIndex);
              });
            }
          }
        })
        .catch((error) => {
          // console.error(false)
          this.isFilmListLoad = true
          this.isFilmListExpire = false;
          this.isFilmListFailed = true;
        })
    },
    jumpInterlibHome () {
      // 在新标签页打开
      window.open(this.$router.resolve({ path: '/en/' }).href, '_blank');
    },
    scrollToItem (index) {
      const itemComponent = this.$refs[`menuItem-${index}`][0];
      const container = this.$refs.storeLeftContainer;

      if (itemComponent && container) {
        const itemTop = itemComponent.offsetTop;
        container.scrollTop = itemTop - container.offsetTop;
      }

      if (index === this.filmListData.length - 1) {
        // 添加延迟执行滚动
        setTimeout(() => {
          this.scrollPage(1);
        }, 100);
      }

      if (this.deviceType === 2 && index > 3) {
        // 添加延迟执行滚动
        setTimeout(() => {
          this.scrollPage(1);
        }, 100);
      }
    },
    //获取店铺详情埋点数据
    fetchStoreRecordLogData () {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      recordStoreLogActionApi('2', locationStoreClickInterlibContentId)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
    //获取店铺详情埋点（浏览结束）数据
    fetchStoreRecordEndLogData () {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      recordStoreLogActionApi('5', locationStoreClickInterlibContentId)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
  },
}
</script>

<style lang="less" scoped>
.shopStore-container {
  font-family: 'Source Han Sans SC-Regular';
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
.shopStore-head {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
}

.shopStore-head-back {
  width: 10px;
  height: auto;
  margin-right: 60px;
  cursor: pointer;
}

.shopStore-head_btn {
  cursor: pointer;
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  border-radius: 50px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 4px;
  .shopStore-head_btn_icon {
    width: 22px;
    margin-right: 6px;
  }
  .shopStore-head_btn_text {
    margin-top: -2px;
  }
}
.shopStore-head-changeLong {
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.store {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  /* background-color: black;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center; */
}

.store-nav {
  // margin: 30px 15px 30px 30px;
  margin: 0 0 30px 30px;
  width: 160px;
  height: calc(100vh - 80px);
  // height: calc(100vh - 110px);
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.footer-logo {
  margin-top: 20px;
  height: 30px;
}

.footer-logo img {
  height: 100%;
}

.store-container {
  flex-grow: 1;
  overflow: scroll;
  // margin-top: 30px;
}

.store-left-nav {
  margin-top: 80px;
  height: calc(100% - 160px);
  border-radius: 8px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
  position: relative;
}

.menu-item {
  transition: transform 0.3s ease;
  margin-top: 22px;
  margin-bottom: 60px;
  width: 70px;
  height: 80px;
  position: relative;
  opacity: 0.4;
}

.menu-item span {
  margin-top: -298px;
  font-size: 10px;
  color: #f0f0f0;
  font-family: Source Han Sans SC-Regular;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  line-height: 1.2;
  width: 130px;
  margin-left: -30px;
  transition: transform 0.3s ease;
}

.menu-item-bg {
  position: absolute;
  left: 21.5px;
  top: 0;
  width: 115px;
  height: 120px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease; /* 添加过渡效果 */
  margin-top: -30px;
}

.menu-item-hovered-bg {
  position: absolute;
  left: 23.5px;
  top: 0px;
  margin-top: -28px;
  width: 113px;
  height: 113px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  transition: top 0.4s ease-out; /* 确保动画平滑 */
}

.menu-item.is-selected {
  transform: scale(1.2);
  transition: transform 0.3s ease;
  opacity: 1;
}

.menu-item.is-hovered {
  transform: scale(1.1);
  transition: transform 0.3s ease;
  opacity: 0.7;
}

.menu-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.scroll-up-btn {
  position: absolute;
  margin-top: 30px;
  left: 50%;
  width: 10px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-down-btn {
  position: absolute;
  left: 50%;
  width: 10px;
  margin-bottom: 30px;
  transform: translateX(-50%);
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 10;
}

.scroll-up-btn {
  top: 10px;
}

.scroll-down-btn {
  bottom: 10px;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

.content-container {
  transform: scale(0.2);
  transform-origin: top left;
  margin-left: -3px;
}

.store-film-expire {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  font-family: "Source Han Sans SC-Normal";
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .store {
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: black;
    background-image: url("~@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .store-nav {
    margin: 20px 0px 30px 5px;
    /* margin: 20px 0px 0 5px; */
    width: 80px;
    height: calc(100vh - 60px);
    /* height: calc(100vh - 30px); */
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .footer-logo {
    margin-top: 10px;
    height: 18px;
  }

  .footer-logo img {
    height: 100%;
  }

  .store-container {
    flex-grow: 1;
    overflow: scroll;
    margin-top: 20px;
  }

  .store-left-nav {
    margin-top: 20px;
    height: calc(100% - 80px);
    border-radius: 8px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    position: relative;
  }

  .menu-item {
    transition: transform 0.3s ease;
    margin-top: 22px;
    margin-bottom: 20px;
    width: 40px;
    height: 50px;
    position: relative;
    opacity: 0.4;
    margin-left: 10px;
  }

  .menu-item span {
    margin-top: -338px;
    font-size: 8px;
    color: #f0f0f0;
    font-family: Source Han Sans SC-Regular;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    line-height: 1.2;
    width: 70px;
    margin-left: -20.5px;
    transition: transform 0.3s ease;
  }

  .menu-item-bg {
    position: absolute;
    left: 9.5px;
    top: 0;
    width: 60px;
    height: 65px;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.15);
    transition: top 0.4s ease; /* 添加过渡效果 */
    margin-top: -22.5px;
  }

  .menu-item-hovered-bg {
    position: absolute;
    left: 23.5px;
    top: 0px;
    margin-top: -28px;
    width: 60px;
    height: 65px;
    border-radius: 8px;
    border: none;
    transition: top 0.4s ease-out; /* 确保动画平滑 */
  }

  .menu-item.is-selected {
    transform: scale(1.2);
    transition: transform 0.3s ease;
    opacity: 1;
  }

  .menu-item.is-hovered {
    transform: scale(1.1);
    transition: transform 0.3s ease;
    opacity: 0.7;
  }

  .menu-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }

  .scroll-up-btn {
    position: absolute;
    margin-top: 40px;
    left: 45px;
    width: 10px;
    transform: translateX(-50%);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
  }

  .scroll-down-btn {
    position: absolute;
    left: 45px;
    width: 10px;
    margin-bottom: -10px;
    transform: translateX(-50%);
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 10;
  }

  .scroll-up-btn {
    top: 10px;
  }

  .scroll-down-btn {
    bottom: 10px;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }

  .content-container {
    transform: scale(0.1);
    transform-origin: top left;
    margin-left: -3px;
  }

  .store-film-expire {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 16px;
    font-family: "Source Han Sans SC-Normal";
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }
}
</style>

