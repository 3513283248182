<template>
  <div class="mine">
    <div class="nav">
      <div class="nav-interlib">
        <img class="nav-interlib-title"
             src="@/assets/home/interlib-logo.png"
             alt="" />
        <span class="nav-interlib-detail">{{ $t('nav-title') }}</span>

      </div>
      <div class="nav-search">
        <img :src="languageType == 'en' ?  require('@/assets/common/chiness.jpg') : require('@/assets/common/english.jpg')"
             @click="navTranslate"
             alt="">
        <search-button class="nav-search-box"
                       placeholderText="Search"></search-button>
      </div>
    </div>
    <div class="mine-container">
      <div class="mine-container-information-password">
        <div class="mine-container-information">
          <span class="mine-container-information-title">{{ $t('mine_basic_information') }}</span>
          <span class="mine-container-information-line"></span>
          <div class="mine-container-information-img">
            <div class="image-container"
                 @click="editAvatarClick">
              <img :src="userInfo.avatar ? userInfo.avatar : isLoggedIn ? require('@/assets/nav/mine-login-default.jpg') : require('@/assets/nav/mine-not-login-default.jpg')"
                   alt="" />
              <div class="edit-shadow">
                <img src="@/assets/nav/mine-edit.png"
                     v-show="isLoggedIn"
                     class="edit"
                     alt="" />
              </div>

              <input type="file"
                     accept="image/*"
                     ref="fileInput"
                     style="display: none;"
                     @change="onFileChange">

            </div>
          </div>

          <span class="item-text">{{$t('mine_change_nickname')}}</span>
          <CustomInput class="item-border"
                       v-model="inputNickname"
                       value=""
                       :placeholder="$t('mine_change_nickname_placeholder')"
                       @input="handleInput" />
          <span class="item-text special">{{$t('mine_change_country')}}</span>
          <DropdownMenu v-if="hasCountry"
                        class="item-border"
                        :placeholder="$t('mine_change_country_placeholder')"
                        :type="'user_country'"
                        :items="user_countrys"
                        :defaultData="selectedCountry"
                        @selected="handleSelected" />
          <DropdownMenuRedBorderVue v-else
                                    class="item-border"
                                    :placeholder="$t('mine_change_country_placeholder')"
                                    :type="'user_country'"
                                    :items="user_countrys"
                                    :defaultData="selectedCountry"
                                    @selected="handleSelected" />
          <span class="item-text special">{{$t('mine_change_email')}}</span>
          <CustomNotInput class="item-border"
                          v-model="inputEmail"
                          value=""
                          editable="false"
                          @input="handleInput" />
          <span class="item-text special">{{$t('mine_change_company_name')}}</span>
          <CustomInput v-if="hasCompany"
                       class="item-border"
                       v-model="inputCompanyName"
                       value=""
                       :placeholder="$t('mine_change_company_name_placeholder')"
                       @input="handleInput" />
          <CustomInputRedBorder v-else
                                class="item-border"
                                v-model="inputCompanyName"
                                value=""
                                :placeholder="$t('mine_change_company_name_placeholder')"
                                @input="handleInput" />
          <span class="item-text special">{{$t('mine_change_company_type')}}</span>
          <DropdownMenu v-if="hasCompanyNature"
                        class="item-border"
                        :placeholder="$t('mine_change_company_type_placeholder')"
                        :type="'company_nature'"
                        :items="company_natures"
                        :defaultData="selectedCompanyType"
                        @selected="handleSelected" />
          <DropdownMenuRedBorderVue v-else
                                    class="item-border"
                                    :placeholder="$t('mine_change_company_type_placeholder')"
                                    :type="'company_nature'"
                                    :items="company_natures"
                                    :defaultData="selectedCompanyType"
                                    @selected="handleSelected" />
          <div>
          </div>
        </div>
        <div class="mine-container-password">
          <span class="mine-container-password-title"> {{ $t('mine_change_password') }} </span>
          <span class="mine-container-password-line"></span>
          <span class="item-text change">{{ $t('mine_change_email') }}</span>
          <CustomNotInput class="item-border"
                          v-model="inputEmail"
                          value=""
                          editable="false"
                          @input="handleInput" />
          <span class="item-text special">{{ $t('mine_verfication_code') }}</span>
          <CustomInputHasCountdown class="item-border verficationCode"
                                   v-model="inputVerficationCode"
                                   value=""
                                   :placeholder="$t('mine_verfication_code_placeholder')"
                                   @input="handleInput"
                                   @get-code="handleGetCodeFromChild" />
          <span class="item-text special">{{ $t('mine_new_password') }}</span>
          <CustomInput class="item-border"
                       v-model="inputNewPassword"
                       value=""
                       :placeholder="$t('mine_new_password_placeholder')"
                       @input="handleInput" />
          <span class="item-text special">{{ $t('mine_confirm_password') }}</span>
          <CustomInput class="item-border"
                       v-model="inputConfirmPassword"
                       value=""
                       :placeholder="$t('mine_confirm_password_placeholder')"
                       @input="handleInput" />
        </div>
      </div>
      <div class="mine-container-preferences">
        <span class="mine-container-preferences-title"> {{ $t('mine_change_preferences') }} </span>
        <span class="mine-container-preferences-line"></span>
        <!-- <div class="menu-wrap">
          <div class="menu">
            <div v-for="(item, index) in menuList"
                 :key="index"
                 :class="{ active: currentIndex === index }"
                 class="menu-item"
                 @click="handleClick(index)"
                 ref="menuItem">
              {{ item.nameEn }}
              <div :class="{ active: currentIndex === index }"
                   class="indicator"></div>
            </div>
          </div>
          <div class="line"></div>
        </div> -->
        <div class="category">
          <div class="category-item"
               v-for="item in categories"
               :key="item.id.toString()"
               @click="selectCategory(item.id)">
            <span :class="{ active: currentIndexId === item.id }">{{ languageType == 'en' ? item.nameEn : item.name }}</span>
            <div class="underline"
                 :class="{ active: currentIndexId === item.id }"></div>
          </div>
        </div>
        <div class="preferences-items">
          <div v-for="item in itemList"
               :key="item.id.toString()"
               class="item"
               :class="{ 'selected': item.isSelected}"
               @click="toggleSelection(item)">
            {{ languageType == 'en' ? item.nameEn : item.name }}
          </div>
        </div>

      </div>
      <div class="submit-tips">
        <span class="submit-tips-success"
              v-if="showTips">{{$t('mine_submit_succes')}}</span>
        <span class="submit-tips-normal"
              v-else>normal</span>
      </div>
      <div class="mine-container-action">
        <div class="mine-container-action-return"
             @click="returnCliclk">
          {{ $t('mine_return') }}
        </div>
        <div class="mine-container-action-submit"
             @click="submitCliclk">
          {{ $t('mine_submit') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import axios from 'axios'

import { userInfoApi, sysDictInfoApi, sendCodeApi, saveUserInfoApi, changePasswordApi, allPreferenceListApi, userPreferenceApi, saveAvatarApi } from '@/api/userApi.js'
import { categoryListApi, genresListApi } from '@/api/discoverApi.js'
import { putPresignedUrlApi } from '@/api/common.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'

import SearchButton from '@/components/SearchButton.vue'
import DropdownMenu from '@/components/DropdownMenu.vue'
import CustomInput from '@/components/CustomInput.vue';
import CustomInputRedBorder from '@/components/CustomInputRedBorder.vue';
import CustomNotInput from '@/components/CustomNotInput.vue';
import CustomInputHasCountdown from '@/components/CustomInputHasCountdown.vue';
import DropdownMenuRedBorderVue from '@/components/DropdownMenuRedBorder.vue';
import { recordUserActionApi } from '@/api/userLogApi.js'

export default {
  name: 'Mine',
  components: {
    SearchButton,
    DropdownMenu,
    DropdownMenuRedBorderVue,
    CustomInput,
    CustomInputRedBorder,
    CustomNotInput,
    CustomInputHasCountdown
  },
  data () {
    return {
      userInfo: {},
      inputNickname: '',
      selectedCountry: '',
      user_countrys: [],
      inputEmail: '',
      inputCompanyName: '',
      selectedCompanyType: '',
      company_natures: [],
      inputVerficationCode: '',
      inputNewPassword: '',
      inputConfirmPassword: '',
      currentIndexId: undefined,
      currentIndexPreferenceData: [],
      categories: [],
      itemList: [],
      selectedItems: [],
      userPreference: {},
      selectedFile: null, //选择的文件
      filesName: null, //文件预上传name
      filesId: null, //文件预上传id
      putPresignedUrl: null, //文件预上传地址
      showTips: false, //是否显示提示
      languageType: "",
      hasCountry: true,
      hasCompany: true,
      hasCompanyNature: true,
      loadTime: 0,
      startTiming: undefined
    }
  },
  mounted () {

    // 在异步函数或延迟操作完成后获取加载时长
    setTimeout(() => {
      const endTiming = performance.now();
      const loadTime = endTiming - this.startTiming;
      console.log('页面加载时长：', loadTime.toFixed(2) * 50);
      this.loadTime = parseInt(loadTime);
      this.recordUserActionApiHomePageData()
    }, 0);
  },
  computed: {
    ...mapState('auth', ['isLoggedIn']),
  },

  created () {
    this.startTiming = performance.now();

    if (this.isLoggedIn == false) {
      this.showLoginDialog()
      return
    }
    this.reloadLocationLanguageData()

    this.userPreferenceData() //个人偏好->获分类数据->1：获题材数据 2：获题材数据 ...
    this.getUserInfoData()
    this.handleGetUserCountry()
    this.handleGetCompanyNature()
  },
  methods: {
    ...mapActions('dialog', ['showLoginDialog']),
    ...mapActions('auth', ['logout']),

    //中英文切换
    navTranslate () {
      console.log("中英文切换:", this.languageType);
      if (this.languageType == 'en') {
        localStorage.setItem('locationLanguageType', 'zh')
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
      const location_href = window.location.href;
      if (location_href.includes('/cn')) {
        let modifiedHref = location_href.replace('/cn', '/' + 'en');
        window.location.href = modifiedHref;
      } else {
        let modifiedHref = location_href.replace('/en', '/' + 'cn');
        window.location.href = modifiedHref;
      }
    },

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    handleClick (index) {
      this.currentIndex = index
    },
    toggleSelection (item) {
      item.isSelected = !item.isSelected
    },
    handleSelected (value, type) {
      if (type == 'user_country') {
        this.selectedCountry = value
        console.log("user_country:", this.selectedCountry);

      } else if (type == 'company_nature') {
        this.selectedCompanyType = value
        console.log("company_nature:", this.selectedCompanyType);
      }
    },
    handleInput (value) {
    },
    handleGetCodeFromChild (countdown) {
      if (this.isLoggedIn == false) {
        this.showLoginDialog()
        return
      }

      const params = {
        email: this.inputEmail,
      }
      sendCodeApi(params)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          if (res.data.code === 200) {
            console.log("res.data.code === 200");
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    // 编辑用户头像
    editAvatarClick () {
      this.$refs.fileInput.click()
    },
    onFileChange (event) {
      if (this.isLoggedIn == false) {
        this.showLoginDialog()
        return
      }
      const file = event.target.files[0]
      if (!file) return
      this.selectedFile = file
      console.log('上传文件:', this.selectedFile)
      this.filesName = this.selectedFile.name
      this.fetchPutPresignedUrlData(this.selectedFile)
    },
    //获取预上传地址数据
    fetchPutPresignedUrlData (file) {
      const params = {
        type: 1, //文件类型 1:图片 2视频 3文档
        fileName: file.name,
        mediaType: file.type
      }

      putPresignedUrlApi(params)
        .then(async (res) => {
          if (res.data.code === 200) {
            this.filesId = res.data.data.fid
            this.putPresignedUrl = res.data.data.url
            console.log('文件预上传Id：', this.filesId)
            console.log('文件预上传URL：', this.putPresignedUrl)
            try {
              const fileURL = this.putPresignedUrl
              const response = await axios.put(
                fileURL,
                this.selectedFile,
                {
                  headers: {
                    'Content-Type': this.selectedFile.type
                  },
                  withCredentials: false,
                }
              )
              if (response.status === 200) {
                console.log('文件预上传成功')
                this.saveAvatar(this.filesId)
              }
            } catch (error) {
              console.log('文件预上传失败')
            }
          }
        })
        .catch((error) => {
          console.log('文件预上传失败')
        })
    },

    //保存头像
    saveAvatar (fid) {
      saveAvatarApi(fid)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.getUserInfoData()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //获取用户信息
    getUserInfoData () {
      userInfoApi()
        .then(async (res) => {
          if (res.data.code === 200) {
            this.userInfo = res.data.data
            this.inputNickname = this.userInfo.username
            this.inputEmail = this.userInfo.email
            this.inputCompanyName = this.userInfo.company
            this.selectedCountry = this.userInfo.country
            this.selectedCompanyType = this.userInfo.companyNature

            //国家
            const country = res.data.data.country ? res.data.data.country : '';
            if (country.length > 0) {
              this.hasCountry = true;
            } else {
              this.hasCountry = false;
            }
            //公司名
            const company = res.data.data.company ? res.data.data.company : '';
            if (company.length > 0) {
              this.hasCompany = true;
            } else {
              this.hasCompany = false;
            }
            //公司性质
            const companyNature = res.data.data.companyNature ? res.data.data.companyNature : '';
            if (companyNature.length > 0) {
              this.hasCompanyNature = true;
            } else {
              this.hasCompanyNature = false;
            }

            try {
              const imageRes = await getPresignedUrlApi(this.userInfo.avatar);
              if (imageRes.data.code === 200) {
                this.userInfo.avatar = imageRes.data.data.url;
              }
            } catch (error) {
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    //获取国家列表
    handleGetUserCountry () {
      const resultData = []
      const params = {
        groupCode: 'user_country'
      }
      console.log(params)
      sysDictInfoApi(params)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              resultData.push(item)
            })
            this.user_countrys = resultData
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    //获取公司类型列表
    handleGetCompanyNature () {
      const resultData = []
      const params = {
        groupCode: 'company_nature'
      }
      console.log(params)
      sysDictInfoApi(params)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              resultData.push(item)
            })
            console.log("获取公司类型列表:", resultData);
            this.company_natures = resultData
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //偏好标签
    userPreferenceData () {
      userPreferenceApi()
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.userPreference = res.data.data
            this.fetchCategoryListData()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //获分类数据
    fetchCategoryListData () {
      this.categories = []
      const resultData = []
      categoryListApi()
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              if (item.showFlag === 1) {
                resultData.push(item)
              }
            })
            this.categories = resultData
            this.currentIndexId = this.categories[0].id

            //遍历分类-获取题材数据
            Promise.all(this.categories.map(async (item) => {
              item.genresList = await this.fetchGenresListData(item.id);
            })).then(() => {
              console.log("遍历分类-获取题材数据 categories:", this.categories);
              this.categories.forEach((item) => {
                const itemGenresList = item.genresList;

                if (this.userPreference.typeGenres && this.userPreference.typeGenres.hasOwnProperty(item.id)) {
                  const targetGenresList = this.userPreference.typeGenres[item.id];

                  itemGenresList.forEach((genre) => {
                    if (targetGenresList.includes(genre.id)) {
                      genre.isSelected = true; // 匹配成功，设置 isCheck 为 true
                    } else {
                      genre.isSelected = false; // 匹配不成功，设置 isCheck 为 false
                    }
                  });
                }
              });
              console.log("遍历分类-获取题材数据-匹配后的数据 categories:", this.categories);

              for (const item of this.categories) {
                if (item.id === this.currentIndexId) {
                  this.itemList = item.genresList;
                  break;
                }
              }
              console.log("遍历分类-获取题材数据-匹配后的数据 itemList:", this.itemList);
            });
          }
        })
        .catch((error) => {
        })
    },

    selectCategory (id) {
      this.currentIndexId = id

      for (const item of this.categories) {
        if (item.id === this.currentIndexId) {
          this.itemList = item.genresList;
          break;
        }
      }
      console.log("selectCategory itemList:", this.itemList);
    },

    //获题材数据
    fetchGenresListData (typeId) {
      return new Promise((resolve, reject) => {
        const resultData = []
        const params = {
          typeId: typeId
        };
        genresListApi(params)
          .then((res) => {
            if (res.data.code === 200) {
              res.data.data.forEach((item) => {
                if (item.showFlag === 1) {
                  item.isSelected = false
                  resultData.push(item)
                }
              })
              resolve(resultData); // 返回处理后的结果数据
            } else {
              reject(new Error("请求失败")); // 返回错误信息
            }
          })
          .catch((error) => {
            reject(error); // 返回错误信息
          });
      });
    },


    //修改基本信息
    saveUserInfo () {
      const data = {
        username: this.inputNickname,
        country: this.selectedCountry,
        company: this.inputCompanyName,
        companyNature: this.selectedCompanyType
      }
      saveUserInfoApi(data)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.showTips = true;
            setTimeout(() => {
              this.showTips = false;
              window.location.reload()
            }, 2000);
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //修改密码
    changePassword () {
      if (!this.inputEmail) {
        return
      }

      if (!this.inputVerficationCode) {
        return
      }

      if (!this.inputNewPassword) {
        return
      }

      if (!this.inputConfirmPassword) {
        return
      }

      if (this.inputNewPassword != this.inputConfirmPassword) {
        return
      }
      const data = {
        email: this.inputEmail,
        code: this.inputVerficationCode,
        newPassword: this.inputNewPassword
      }

      changePasswordApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.logout()
            this.showLoginDialog()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //个人偏好
    preference () {


      this.categories.forEach((item) => {
        let selectedItems = [];
        const genresList = item.genresList;
        const selectedGenres = genresList.filter((genre) => genre.isSelected === true);

        selectedGenres.forEach((genre) => {
          selectedItems.push(genre.id);
        });
        console.log("selectedItems:", selectedItems)

        const typeId = item.id; // 获取类型ID
        const typeGenres = selectedItems; // 获取isSelect为true的对象的id

        const data = {
          status: [0, 1, 2, 3],
          typeId,
          typeGenres
        };

        allPreferenceListApi(data)
          .then((res) => {
            console.log(res);
            if (res.data.code === 200) {
            }
          })
          .catch((error) => {
            console.error(error);
          });

      });
    },

    //提交
    submitCliclk () {
      if (this.isLoggedIn == false) {
        this.showLoginDialog()
        return
      }
      console.log(this.categories);
      // 修改基本信息
      this.saveUserInfo()
      //修改密码
      this.changePassword()
      //个人偏好
      this.preference()
    },

    //重置
    returnCliclk () {
      if (this.isLoggedIn == false) {
        this.showLoginDialog()
        return
      }
      this.reloadLocationLanguageData()
      this.userPreferenceData() //个人偏好->获分类数据->1：获题材数据 2：获题材数据 ...
      this.getUserInfoData()
      this.handleGetUserCountry()
      this.handleGetCompanyNature()
    },
    //记录数据
    async recordUserActionApiHomePageData () {
      try {
        const res = recordUserActionApi("Mine", this.loadTime * 50);
      } catch (error) {
        // 错误处理
      }
    }
  }
}
</script>

<style scoped>
.mine {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

/* Nav---start */
.nav {
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  margin: 10px 40px 0 30px;
}

.nav-interlib {
  flex-grow: 1;
  height: 80px;
  display: flex;
  align-items: center;
}

.nav-interlib-title {
  width: 120px;
  height: auto;
  margin-right: 5px;
}

.nav-interlib-detail {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  margin-left: 5px;
}

.nav-search {
  width: 330px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.nav-search img {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
}
/* Nav---end */

.mine-container {
  margin: 0px 40px 20px 30px;
  display: flex;
  flex-direction: column;
}

.mine-container-information-password {
  height: 630px;
  display: flex;
  flex-direction: row;
}

.mine-container-information {
  flex-grow: 1;
  border: 1px dashed #ccc;
  border-radius: 25px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.mine-container-information-title {
  margin-top: 35px;
  color: #fff;
  font-size: 20px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 10px;
}

.mine-container-information-line {
  width: 18px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
  margin-bottom: 5px;
}

.mine-container-information-img {
  width: 70%;
  display: flex;
  justify-content: right;
}

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #fff;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.mine-container-information-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.edit-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0)
  ); /* 渐变背景 */
  /* opacity: 0.5; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-shadow img {
  width: 15px;
  height: auto;
  margin-top: 15px;
  border-radius: 0px;
}

.mine-container-password {
  margin-left: 15px;
  width: 50%;
  border: 1px dashed #ccc;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.mine-container-password-title {
  margin-top: 35px;
  color: #fff;
  font-size: 20px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 10px;
}

.mine-container-password-line {
  width: 18px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
  margin-bottom: 5px;
}

.mine-container-preferences {
  border-radius: 25px;
  border: 1px dashed #ccc;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

.mine-container-preferences-title {
  margin-top: 35px;
  color: #fff;
  font-size: 20px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 10px;
}

.mine-container-preferences-line {
  width: 18px;
  height: 2px;
  border-radius: 1px;
  background-color: #fff;
  margin-bottom: 5px;
}

/* Category---start */
.category {
  display: flex;
  justify-content: left;
  align-items: start;
  align-content: start;
  height: 40px;
  overflow: hidden;
  width: 93%;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
}

.category-item {
  position: relative;
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  margin-right: 40px;
  font-family: Source Han Sans SC-Medium;
}

.category-item span.active {
  color: #fff;
  font-family: Source Han Sans SC-Medium;
  font-size: 20px;
}

.category-item .underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 17px;
  height: 3px;
  background-color: #fff;
  border-radius: 1.5px;
  transition: all 0.3s ease-in-out;
}

.category-item .underline.active {
  transform: translateX(-50%) translateY(-100%);
}
/* Category---end */

.menu-wrap {
  display: flex;
  width: 94%;
  flex-direction: column;
  margin-top: 20px;
}

.menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-item {
  font-size: 20px;
  font-family: Source Han Sans SC-Normal;
  margin: 10px 0px;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.active {
  font-size: 22px;
  font-family: Source Han Sans SC-Bold;
}

.indicator {
  width: 380px;
  height: 3px;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 1.5px;
  margin-top: 12px;
  z-index: 10;
}

.indicator.active {
  background-color: #ff036a;
}

.line {
  background-color: #ddd;
  width: 100%;
  height: 1px;
  z-index: 3;
  margin-top: -12px;
}

.preferences-items {
  width: calc(100% - 80px);
  margin: 30px 40px;
  /* margin-top: 20px; */
  /* margin-bottom: 30px; */
}

.item {
  display: inline-block;
  width: calc(100% / 6.5);
  text-align: center;
  padding: 2% 0;
  border: 1px dashed #777;
  margin-right: 1%;
  margin-bottom: 1%;

  cursor: pointer;
  border-radius: 15px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
}

.item.selected {
  border: 1px solid white;
  background-color: transparent;
}

.submit-tips {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.submit-tips-success {
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  padding: 3px 20px;
}
.submit-tips-normal {
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  color: transparent;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 20px;
  padding: 3px 20px;
}

.mine-container-action {
  height: 140px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mine-container-action-return {
  width: 200px;
  height: 40px;
  background-color: #9b9b9b;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  cursor: pointer;
}

.mine-container-action-submit {
  width: 200px;
  height: 40px;
  background-color: #9b9b9b;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  cursor: pointer;
}

.item-border {
  width: 70%;
}

.item-text {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  text-align: left;
  width: 70%;
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.item-text.special {
  margin-top: 15px;
}

.item-text.change {
  margin-top: 86px;
}
</style>


<style scoped>
@media only screen and (max-width: 1024px) {
  .mine {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  /* Nav---start */
  .nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-between;
    align-items: center;
    margin-left: 14px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    height: 30px;
  }

  .nav-interlib {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .nav-interlib-title {
    width: 120px;
    height: auto;
    margin-right: 5px;
  }

  .nav-interlib-detail {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin-left: 5px;
    display: none;
  }

  .nav-search {
    width: 130px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .nav-search img {
    width: 25px;
    height: 25px;
    margin-right: 1px;
    cursor: pointer;
  }
  /* Nav---end */

  .mine-container {
    margin: 0px 30px 20px 14px;
    display: flex;
    flex-direction: column;
  }

  .mine-container-information-password {
    height: 540px;
    display: flex;
    flex-direction: row;
  }

  .mine-container-information {
    flex-grow: 1;
    border: 1px dashed #ccc;
    border-radius: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 50%;
  }

  .mine-container-information-title {
    margin-top: 25px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 10px;
  }

  .mine-container-information-line {
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 5px;
  }

  .mine-container-information-img {
    width: 70%;
    display: flex;
    justify-content: right;
  }

  .image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #fff;
    width: 50px;
    height: 50px;
    cursor: pointer;
  }

  .mine-container-information-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .edit-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    /* opacity: 0.5; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-shadow img {
    width: 10px;
    height: auto;
    margin-top: 15px;
    border-radius: 0px;
  }

  .mine-container-password {
    margin-left: 15px;
    width: 50%;
    border: 1px dashed #ccc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .mine-container-password-title {
    margin-top: 25px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 10px;
  }

  .mine-container-password-line {
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 5px;
  }

  .mine-container-preferences {
    border-radius: 20px;
    border: 1px dashed #ccc;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .mine-container-preferences-title {
    margin-top: 25px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 10px;
  }

  .mine-container-preferences-line {
    width: 18px;
    height: 2px;
    border-radius: 1px;
    background-color: #fff;
    margin-bottom: 5px;
  }

  /* Category---start */
  .category {
    display: flex;
    justify-content: left;
    align-items: start;
    align-content: start;
    height: 40px;
    overflow: hidden;
    width: 93%;
    margin-top: 0px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .category-item {
    position: relative;
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    color: #fff;
    margin-right: 40px;
    font-family: Source Han Sans SC-Medium;
  }

  .category-item span.active {
    color: #fff;
    font-family: Source Han Sans SC-Medium;
    font-size: 14px;
  }

  .category-item .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 17px;
    height: 3px;
    background-color: #fff;
    border-radius: 1.5px;
    transition: all 0.3s ease-in-out;
  }

  .category-item .underline.active {
    transform: translateX(-50%) translateY(-100%);
  }
  /* Category---end */

  .menu-wrap {
    display: flex;
    width: 94%;
    flex-direction: column;
    margin-top: 20px;
  }

  .menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .menu-item {
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin: 10px 0px;
    color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .active {
    font-size: 16px;
    font-family: Source Han Sans SC-Bold;
  }

  .indicator {
    width: 380px;
    height: 3px;
    background-color: transparent;
    transition: transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 1.5px;
    margin-top: 12px;
    z-index: 10;
  }

  .indicator.active {
    background-color: #ff036a;
  }

  .line {
    background-color: #ddd;
    width: 100%;
    height: 1px;
    z-index: 3;
    margin-top: -12px;
  }

  .preferences-items {
    width: calc(100% - 80px);
    margin: 30px 40px;
    /* margin-top: 20px; */
    /* margin-bottom: 30px; */
  }

  .item {
    display: inline-block;
    width: calc(100% / 5 - 8px);
    text-align: center;
    padding: 10px 10px;
    border: 1px dashed #777;
    margin-right: 10px;
    margin-bottom: 10px;

    cursor: pointer;
    border-radius: 15px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
  }

  .item.selected {
    border: 1px solid white;
    background-color: transparent;
  }

  .submit-tips {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .submit-tips-success {
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    padding: 3px 20px;
  }
  .submit-tips-normal {
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    color: transparent;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 20px;
    padding: 3px 20px;
  }

  .mine-container-action {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .mine-container-action-return {
    width: 100px;
    height: 30px;
    background-color: #9b9b9b;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    cursor: pointer;
    margin-bottom: 60px;
  }

  .mine-container-action-submit {
    width: 100px;
    height: 30px;
    background-color: #9b9b9b;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    cursor: pointer;
    margin-bottom: 60px;
  }

  .item-border {
    width: 69%;
  }

  .item-text {
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: left;
    text-align: left;
    width: 70%;
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }

  .item-text.special {
    margin-top: 15px;
  }

  .item-text.change {
    margin-top: 56px;
  }
}
</style>
