<template>
  <el-input clearable
            :editable=false
            :readonly="true"
            :placeholder="placeholder"
            v-model="inputValue"
            @input="handleInput" />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      inputValue: this.value,
    };
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    handleInput (value) {
      this.inputValue = value;
      this.$emit('input', value);
    },
  },
};
</script>


<style lang="less" scoped>
::v-deep .el-input__inner {
  border-radius: 24px !important;
  border: 0.5px solid #fff;
  height: 32px;
  padding: 0px 15px 2px 15px;
  background-color: transparent;
  color: #9b9b9b;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1;
}

::v-deep .el-input__icon {
  line-height: 32px !important;
}

.el-input--suffix .el-input__inner {
  border: 0.5px solid #fff !important;
  line-height: 1;
}

.el-input__inner:focus {
  border: 0.5px solid #fff !important;
}

.el-input input::placeholder {
  color: #9b9b9b;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}
</style>


<style lang="less" scoped>
@media only screen and (max-width: 1024px) {
  ::v-deep .el-input__inner {
    border-radius: 24px !important;
    border: 0.5px solid #fff;
    height: 32px;
    padding: 0px 15px 2px 15px;
    background-color: transparent;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1;
  }

  ::v-deep .el-input__icon {
    line-height: 32px !important;
  }

  .el-input--suffix .el-input__inner {
    border: 0.5px solid #fff !important;
    line-height: 1;
  }

  .el-input__inner:focus {
    border: 0.5px solid #fff !important;
  }

  .el-input input::placeholder {
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }
}
</style>
