import {mapState, mapGetters} from 'vuex'
import {deepClone} from "@/utils/utils";

export default {
  data() {
    return {
      ts: window.localStorage.getItem('locationLanguageType') || 'en',
    }
  },
  created() {
  },
  mounted() {
    // this.init()
    // let self = this
    // window.onresize = function () {
    //   // 定义窗口大小变更通知事件
    //   self.topFormWrapperSpan = self.topFormWrapperSpanFunc()
    //   console.log(self.topFormWrapperSpan);
    // };

  },
  computed: {


  },
  methods: {
    init() {

    },
    messageSucc: function (params) {
      let options = {
        showClose: false,
        message: '成功',
        type: 'success',
        customClass: 'diy-success',
        iconClass: 'none',
        duration: 1500,
        offset: document.body.clientHeight / 2 - 49
      };
      if (typeof (params) === 'string') {
        options.message = params;
        this.$message(options);
      } else {
        this.$message(Object.assign({}, options, params));
      }
    },
    messageErr: function (params) {
      if (params) {
        let options = {
          showClose: false,
          message: '失败',
          type: 'success',
          customClass: 'diy-error',
          iconClass: 'none',
          duration: 1500,
          offset: document.body.clientHeight / 2 - 49
        }
        if (typeof (params) === 'string') {
          options.message = params;
          this.$message(options);
        } else {
          this.$message(Object.assign({}, options, params));
        }
      }

    },
    doCopy(text) {
      this.$copyText(text).then(
        (e) => {
          this.messageSucc(this.ts === 'en' ? 'Successful replication' : '复制成功');
        },
        (e)=> {
          this.messageErr(this.ts === 'en' ? 'Replication failure' : '复制失败');
        })
    },


  }
}
