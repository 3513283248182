import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history', //去掉url中的#
    routes: [
      //  {
      //       path: '/:language/',
      //       name: 'language-home',
      //       component: () => import('../views/Home.vue'), //异步加载组件
      //       meta: {
      //         keepAlive: true 
      //       }
      //   },
      {
        path: '/',
        name: 'language-shopHome',
        component: () => import('../views/Shop.vue'), //异步加载组件
      },
        {
          path: '/:language/',
          name: 'language-shopHome',
          component: () => import('../views/Shop.vue'), //异步加载组件
          // meta: {
          //   keepAlive: true 
          // }
      },
        {
            path: '/:language/discover',
            name: 'language-discover',
            component: () => import('../views/Discover.vue'),
            meta: {
              keepAlive: true 
            }
        },
        {
            path: '/:language/collect',
            name: 'language-collect',
            component: () => import('../views/Collect/Collect.vue'),
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/About.vue'),
        },
        {
            path: '/:language/mine',
            name: 'language-mine',
            component: () => import('../views/Mine.vue'),
        },
        {
            path: '/:language/collectDetail/:id',
            name: 'collect-detail',
            component: () => import('../views/Collect/CollectDetail.vue'),
        },
        {
            path: '/:language/:id-:name',
            name: 'detail',
            component: () => import('../views/Detail/DetailNewPage.vue'),
      },
         {
            path: '/:language/DEV',
            name: 'DEV',
            component: () => import('../views/DEV.vue'),
      },
         {
            path: '/:language/contactUs',
            name: 'language-contact-us',
            component: () => import('../views/ContactUs.vue'),
      },
         {
            path: '/:language/tools',
            name: 'language-tools',
            component: () => import('../views/Tools.vue'),
      },

      // 店铺首页
      {
        path: '/:language/shop',
        // path: '/:deviceType/:language/shop',
        name: 'language-shop',
        component: () => import('../views/Shop.vue'),
        // meta: {
        //   keepAlive: true 
        // }
      },
      // 店铺接待室
      {
        path: '/:language/shopSaloon',
        name: 'language-shopSaloon',
        component: () => import('../views/shop/shopSaloon.vue'),
        // meta: {
        //   keepAlive: true 
        // }
      },
      // 店铺介绍页
      {
        path: '/:language/shopProfile',
        name: 'language-shopProfile',
        component: () => import('../views/shop/shopProfile.vue'),
        // meta: {
          // keepAlive: true 
        // }
      },
      // 店铺单剧详情页
      {
        path: '/:language/shopDramaInfo',
        name: 'language-shopDramaInfo',
        meta: {
          // 不需要layout页面
          noShopLayout: true,
        },
        component: () => import('../views/shop/shopDramaInfo.vue'),
      },
      // 店铺邮件片单
      {
        path: '/:language/shopStore',
        name: 'language-shopStore',
        component: () => import('@/views/shopStore/StorePage.vue'),
        meta: {
          // 不需要layout页面
          noShopLayout: true,
        },
      },
      // 店铺面对面选片推送
      {
        path: '/:language/shopFace',
        name: 'language-shopFace',
        component: () => import('@/views/shopFaceToFace/index.vue'),
        meta: {
          // 不需要layout页面
          noShopLayout: true,
        },
      },
      // 店铺面对面选片推送
      {
        path: '/:language/shopFaceToFace',
        name: 'language-shopFaceToFace',
        component: () => import('@/views/shopFaceToFace/shopDetailPage.vue'),
        meta: {
          // 不需要layout页面
          noShopLayout: true,
        },
      },
      //最后一个路由配置404页面，即用户访问了不存在的路由后同意跳转到404页面//注意!!!一定要放在最后一项，否则在此路由后配置的的路由都将失效。
      { path: '/*', redirect: '/:language/shop' },
    ],
});

router.afterEach((to, from) => {
  console.log('afterEach', to, from)
  setTimeout(() => {
    (function () {
      
      const hostname = window.location.hostname;
      console.log("hostname:", hostname);
      
      // 判断是否为测试环境
      const isQa= hostname.includes("qa");
      console.log("hostname:", isQa);

      const isLocalhost= hostname.includes("localhost");
      console.log("hostname:", isLocalhost);

      const is192= hostname.includes("192");
      console.log("hostname:", is192);
      
      // 如果是测试环境，不执行百度统计代码
      if (isQa || isLocalhost || is192) {
        console.log("如果是测试环境，不执行百度统计代码");
        return;
      }
      
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?8df1a81e8eb205adb7276faa222e44f4";
      hm.id = "baidu_tj";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});


export default router;
