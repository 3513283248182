<template>
  <div class="search-wrapper"
       :class="{ active: isSearchActive }"
       @click="activateSearch">
    <img class="search-icon"
         :src="isSearchActive ? searchIconActive : searchIconDisabled"
         :style="searchIconStyle"
         v-if="!isSearchActive"
         @click="activateSearch" />
    <!-- <button class="search-btn"
            v-else
            :disabled="true">
      <img src="@/assets/nav/search.png"
           alt=""
           @click="activateSearch" />
    </button> -->
    <!-- <div ref="searchInput"
         class="search-input">{{ $t('nav_search') }}</div> -->
  </div>
</template>

<script>
export default {
  name: 'SearchBox',
  data () {
    return {
      isSearchActive: false,
      searchText: '',
      languageType: "",
    }
  },
  props: {
    placeholderText: {
      type: String,
      default: 'Search'
    },
    searchIconActive: {
      type: String,
      default: require('@/assets/nav/search.png')
    },
    searchIconDisabled: {
      type: String,
      default: require('@/assets/nav/search.png')
    }
  },
  created () {
    this.reloadLocationLanguageData()
  },
  computed: {
    searchIconStyle () {
      if (this.isSearchActive) {
        return {
          right: '20px'
        }
      } else {
        return {
          left: '20px'
        }
      }
    }
  },
  methods: {
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
      }
    },
    activateSearch () {
      console.log('activate search');
      this.goToDiscoverPage()

    },
    goToDiscoverPage () {
      this.$router.push({ name: 'language-discover', params: { language: this.languageType === 'zh' ? 'cn' : 'en' } })
    }
  }
}
</script>

<style scoped lang="less">
.search-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  // width: 100%;
  height: 100%;
  // border: 0.5px solid #999;
  border-radius: 15px;
  transition: all 0.3s;
  user-select: none;
  cursor: pointer;
}

.search-icon {
  position: absolute;
  top: 6px;
  left: 0px;
  width: 18px;
  height: 18px;
  transition: all 0.3s;
}

.search-btn {
  position: absolute;
  top: 0px;
  right: 2px;
  padding: 4px 8px;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
}

.search-btn img {
  width: 100%;
  height: 100%;
}

.search-input {
  position: absolute;
  top: 0;
  left: 15px;
  width: 80%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 15px;
  background-color: transparent;
  font-size: 14px;
  padding-left: 40px;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: left;
  color: #999;
}

.search-input::placeholder {
  color: #999;
  opacity: 0.8;
}

.search-input:disabled {
  cursor: pointer;
}

.search-input:enabled {
  cursor: text;
  padding-left: 10px;
}
</style>
