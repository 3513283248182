import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import './assets/css/font.css';
import './assets/css/shopCommon.less'
import mixins  from './mixins/index'
import Vuex from 'vuex';
import dialog from './store/dialog';
import auth from './store/auth';
import collectDialog from './store/collectDialog';
import deatilEra from './store/deatilEra';

import store from '@/store/index';

import VueI18n from 'vue-i18n';
import en from '../src/locales/en.json';
import zh from '../src/locales/zh.json';

import MetaInfo from 'vue-meta-info'
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

// 注册
Vue.use(MetaInfo)

Vue.use(VueI18n);

Vue.use(Vuex);
Vue.mixin(mixins);

//Select 解决iOS上键盘不弹窗问题
// ElementUI.Select.computed.readonly = function () {
// const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
// return !(this.filterable || this.multiple || !isIE) && !this.visible;
// };
Vue.use(ElementUI);


// 【图片地址加载失败时，隐藏图裂显示默认值】将封装的自定义指令挂载到Vue上(main.js)
// import { imgerror } from '@/directives'
import * as ALLdirectives from '@/utils/directives'
import mine from "@/views/Mine.vue";
// 遍历得到的对象, 批量进行自定义指令的注册
for (const key in ALLdirectives) {
  // console.log(key, directives[key])
  Vue.directive(key, ALLdirectives[key])
}


Vue.config.productionTip = false;

const messages = {
  en,
  zh
};

const i18n = new VueI18n({
  locale: 'en', // 默认语言
  messages
});

// 在应用程序初始化时调用 getUserInfo action
store.dispatch('auth/getUserInfo')
  .then(() => {
    new Vue({
    router,
    store,
    i18n, // 将 i18n 作为 Vue 实例的选项
    render: h => h(App),
    }).$mount('#app');
});
