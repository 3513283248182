// 判断数据类型
let checkType = data => {
  let t = Object.prototype.toString.call(data).slice(8,-1)
  return t
}
// 深拷贝
export function deepClone (target)  {
  let targetType  = checkType(target)
  let result
  if(targetType === "Object"){
    result = {}
  }else if(targetType === "Array"){
    result = []
  }else{
    return target
  }
  for(let i in target){
    let value = target[i] // 每个属性对应的值
    // 兼容引用数据类型中还存在引用数据类型的情况
    let valueType = checkType(value)
    if(valueType === "Object" || valueType === "Array"){
      result[i] = deepClone(value) // 递归
    }else{
      result[i] = value
    }
  }
  return result
}
export function dateFormat(fmt, date) {
  date = new Date(date);
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(),        // 年
    "m+": (date.getMonth() + 1).toString(),     // 月
    "d+": date.getDate().toString(),            // 日
    "H+": date.getHours().toString(),           // 时
    "M+": date.getMinutes().toString(),         // 分
    "S+": date.getSeconds().toString()          // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}

//获取css translates x y rotate
export function getTranslate(node, sty) {
  let translates = document.defaultView.getComputedStyle(node, null).transform.substring(7);
  let result = translates.match(/\(([^)]*)\)/);// 正则()内容
  let matrix = result ? result[1].split(',') : translates.split(',');
  if (sty == "x" || sty == undefined) {
    return matrix.length > 6 ? parseFloat(matrix[12]) : parseFloat(matrix[4]);
  } else if (sty == "y") {
    return matrix.length > 6 ? parseFloat(matrix[13]) : parseFloat(matrix[5]);
  } else if (sty == "z") {
    return matrix.length > 6 ? parseFloat(matrix[14]) : 0;
  } else if (sty == "rotate") {
    return matrix.length > 6 ? getRotate([parseFloat(matrix[0]), parseFloat(matrix[1]), parseFloat(matrix[4]), parseFloat(matrix[5])]) : getRotate(matrix);
  }
}
function getRotate(matrix) {
  let aa = Math.round(180 * Math.asin(matrix[0]) / Math.PI);
  let bb = Math.round(180 * Math.acos(matrix[1]) / Math.PI);
  let cc = Math.round(180 * Math.asin(matrix[2]) / Math.PI);
  let dd = Math.round(180 * Math.acos(matrix[3]) / Math.PI);
  let deg = 0;
  if (aa == bb || -aa == bb) {
    deg = dd;
  } else if (-aa + bb == 180) {
    deg = 180 + cc;
  } else if (aa + bb == 180) {
    deg = 360 - cc || 360 - dd;
  }
  return deg >= 360 ? 0 : deg;
}



//数字转为中文大写金额
export function changeToChineseMoney(Num) {
  //判断如果传递进来的不是字符的话转换为字符
  if (typeof Num == "number") {
    Num = new String(Num);
  };
  Num = Num.replace(/,/g, "") //替换tomoney()中的“,”
  Num = Num.replace(/ /g, "") //替换tomoney()中的空格
  Num = Num.replace(/￥/g, "") //替换掉可能出现的￥字符
  if (isNaN(Num)) { //验证输入的字符是否为数字
    //alert("请检查小写金额是否正确");
    return "";
  };
  //字符处理完毕后开始转换，采用前后两部分分别转换
  let part = String(Num).split(".");
  let newchar = "";
  //小数点前进行转化
  for (let i = part[0].length - 1; i >= 0; i--) {
    if (part[0].length > 10) {
      return "";
      //若数量超过拾亿单位，提示
    }
    let tmpnewchar = ""
    let perchar = part[0].charAt(i);
    switch (perchar) {
      case "0":
        tmpnewchar = "零" + tmpnewchar;
        break;
      case "1":
        tmpnewchar = "壹" + tmpnewchar;
        break;
      case "2":
        tmpnewchar = "贰" + tmpnewchar;
        break;
      case "3":
        tmpnewchar = "叁" + tmpnewchar;
        break;
      case "4":
        tmpnewchar = "肆" + tmpnewchar;
        break;
      case "5":
        tmpnewchar = "伍" + tmpnewchar;
        break;
      case "6":
        tmpnewchar = "陆" + tmpnewchar;
        break;
      case "7":
        tmpnewchar = "柒" + tmpnewchar;
        break;
      case "8":
        tmpnewchar = "捌" + tmpnewchar;
        break;
      case "9":
        tmpnewchar = "玖" + tmpnewchar;
        break;
    }
    switch (part[0].length - i - 1) {
      case 0:
        tmpnewchar = tmpnewchar + "元";
        break;
      case 1:
        if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
        break;
      case 2:
        if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
        break;
      case 3:
        if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
        break;
      case 4:
        tmpnewchar = tmpnewchar + "万";
        break;
      case 5:
        if (perchar != 0) tmpnewchar = tmpnewchar + "拾";
        break;
      case 6:
        if (perchar != 0) tmpnewchar = tmpnewchar + "佰";
        break;
      case 7:
        if (perchar != 0) tmpnewchar = tmpnewchar + "仟";
        break;
      case 8:
        tmpnewchar = tmpnewchar + "亿";
        break;
      case 9:
        tmpnewchar = tmpnewchar + "拾";
        break;
    }
    let newchar = tmpnewchar + newchar;
  }
  //小数点之后进行转化
  if (Num.indexOf(".") != -1) {
    if (part[1].length > 2) {
      // alert("小数点之后只能保留两位,系统将自动截断");
      part[1] = part[1].substr(0, 2)
    }
    for (i = 0; i < part[1].length; i++) {
      tmpnewchar = ""
      perchar = part[1].charAt(i)
      switch (perchar) {
        case "0":
          tmpnewchar = "零" + tmpnewchar;
          break;
        case "1":
          tmpnewchar = "壹" + tmpnewchar;
          break;
        case "2":
          tmpnewchar = "贰" + tmpnewchar;
          break;
        case "3":
          tmpnewchar = "叁" + tmpnewchar;
          break;
        case "4":
          tmpnewchar = "肆" + tmpnewchar;
          break;
        case "5":
          tmpnewchar = "伍" + tmpnewchar;
          break;
        case "6":
          tmpnewchar = "陆" + tmpnewchar;
          break;
        case "7":
          tmpnewchar = "柒" + tmpnewchar;
          break;
        case "8":
          tmpnewchar = "捌" + tmpnewchar;
          break;
        case "9":
          tmpnewchar = "玖" + tmpnewchar;
          break;
      }
      if (i == 0) tmpnewchar = tmpnewchar + "角";
      if (i == 1) tmpnewchar = tmpnewchar + "分";
      newchar = newchar + tmpnewchar;
    }
  }
  //替换所有无用汉字
  while (newchar.search("零零") != -1)
    newchar = newchar.replace("零零", "零");
  newchar = newchar.replace("零亿", "亿");
  newchar = newchar.replace("亿万", "亿");
  newchar = newchar.replace("零万", "万");
  newchar = newchar.replace("零元", "元");
  newchar = newchar.replace("零角", "");
  newchar = newchar.replace("零分", "");
  if (newchar.charAt(newchar.length - 1) == "元") {
    newchar = newchar + "整"
  }
  return newchar;
}


//数字转为中文数字
export function numberToChinese(num) {
  let AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九", "十");
  let BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
  let a = ("" + num).replace(/(^0*)/g, "").split("."),
    k = 0,
    re = "";
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0:
        re = BB[7] + re;
        break;
      case 4:
        if (!new RegExp("0{4}//d{" + (a[0].length - i - 1) + "}$")
          .test(a[0]))
          re = BB[4] + re;
        break;
      case 8:
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
    }
    if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
      re = AA[0] + re;
    if (a[0].charAt(i) != 0)
      re = AA[a[0].charAt(i)] + BB[k % 4] + re;
    k++;
  }
  if (a.length > 1) // 加上小数部分(如果有小数部分)
  {
    re += BB[6];
    for (let i = 0; i < a[1].length; i++)
      re += AA[a[1].charAt(i)];
  }
  if (re == '一十')
    re = "十";
  if (re.match(/^一/) && re.length == 3)
    re = re.replace("一", "");
  return re;
}

//限制上传图片尺寸
export function upImgWH(files, w = 600, h = 800) {
  let flag = false;
  let file = files.raw;
  let url = window.URL.createObjectURL(file);
  let image = new Image();
  image.src = url;
  return new Promise(resolve => {
    image.onload = function () {
      if (image.width === w || image.height === h) {
        resolve(true)
      } else {
        resolve(false)
      }
    }
  })

}

// 下载
// @param  {String} url 目标文件地址
// @param  {String} filename 想要保存的文件名称
export function courseDownload(url, filename) {
  getBlob(url, function(blob) {
    saveAs(blob, filename);
  })
}
function getBlob(url,cb) {
  let xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function() {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  }
  xhr.send();
}
// 保存
// @param  {Blob} blob
// @param  {String} filename 想要保存的文件名称
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

//获取base64图片大小，返回kb数字

export function showImgSize(base64url) {
  //把头部去掉
  let str = base64url.replace('data:image/png;base64,', '');
  // 找到等号，把等号也去掉
  let equalIndex = str.indexOf('=');
  if (str.indexOf('=') > 0) {
    str = str.substring(0, equalIndex);
  }
  // 原来的字符流大小，单位为字节
  let strLength = str.length;
  // 计算后得到的文件流大小，单位为字节
  let fileLength = parseInt(strLength - (strLength / 8) * 2);
  // 由字节转换为kb
  let size = "";
  size = (fileLength / 1024).toFixed(2);
  let sizeStr = size + ""; //转成字符串
  let index = sizeStr.indexOf("."); //获取小数点处的索引
  let dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
  if (dou == "00") { //判断后两位是否为00，如果是则删除00
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size;
}

export function base64ToFile (dataurl, filename) {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1];
  if(!filename) {
    filename = `${new Date().getTime()}.${mime.substr(mime.indexOf('/') + 1)}`
  }
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}
/**
 * 根据身份证号得到姓别和精确计算年龄
 */
export function analyzeIDCard(IDCard) {
  //获取用户身份证号码
  let userCard = IDCard + '';
  //如果身份证号码为undefind则返回空
  if (!userCard) {
    return false;
  }
  //获取出生年月日
  let yearBirth = userCard.substring(6, 10);
  let monthBirth = userCard.substring(10, 12);
  let dayBirth = userCard.substring(12, 14);
  //获取当前年月日并计算年龄
  let myDate = new Date();
  let monthNow = myDate.getMonth() + 1;
  let dayNow = myDate.getDate();
  let age = myDate.getFullYear() - yearBirth;
  if (monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)) {
    age--;
  }
  console.log(age);
  if (age >= 18) {
    return true;
  }
  return false;
}


/*
* 判断移动还是pc
* */
export function equipmentFunc(){
  let uA = navigator.userAgent.toLowerCase();
  // alert(uA)
  let ipad = uA.match(/ipad/i) == "ipad";
  let iphone = uA.match(/iphone os/i) == "iphone os";
  let midp = uA.match(/midp/i) == "midp";
  let uc7 = uA.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
  let uc = uA.match(/ucweb/i) == "ucweb";
  let android = uA.match(/android/i) == "android";
  let windowsce = uA.match(/windows ce/i) == "windows ce";
  let windowsmd = uA.match(/windows mobile/i) == "windows mobile";
  let isMobile = window.screen.availWidth < 768;
  let isPc = window.screen.availWidth >= 768;
  let isTablet = window.screen.availWidth >= 768 && window.screen.availWidth <= 1024
  return {
    isPhone: iphone,
    isAndroid: android,
    ipad: ipad,
    isTablet: isTablet || ipad,
    isPc: isPc
  };
}

// 方法1
export function weixin() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) == 'micromessenger' // true or false
}


export function isDingTalk() {
  const ua = window.navigator.userAgent
  return ua.match(/MicroMessenger/i) == 'DingTalk' // true or false
}


export function titleCase(str) {
  //将字符串分解为数组并将其小写化
  let convertToArray = str.toLowerCase().split(" ");

  for (let i = 0; i < convertToArray.length; i++) {
    let char = convertToArray[i].charAt(0);
    //使用 replace()方法将数组中的每个首字母大写化
    convertToArray[i] = convertToArray[i].replace(char, function replace(char) {
      return char.toUpperCase();
    });
  }
  return convertToArray.join(" ");
}

export const getBase64Image = async (src) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = src;
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(image, 0, 0, image.width, image.height);
      const dataURL = canvas.toDataURL("image/jpeg", .95);
      // console.log('---getBase64Image', dataURL);
      resolve(dataURL);
    };
  });
};

export function containsIds(dataList, ids) {
  const foundIds = new Set();

  function traverse(list) {
    for (let item of list) {
      if (ids.includes(item.id)) {
        foundIds.add(item.id);
      }
      if (item.childList && item.childList.length > 0) {
        traverse(item.childList);
      }
    }
  }

  traverse(dataList);
  return ids.every(id => foundIds.has(id));
}

/**
 * 表格合并计算方法
 * 分析每一列，找出所有【列】可合并（数据相同）的单元格
 * @param {Array} tableData 表数据
 * @param {Array} tableColumn 表字段/表头
 * @param {Array} mergeCols 指定合并哪些列（字段）
 * @returns
 */
export const getMergeCells = (tableData = [], tableColumn = [], mergeCols = []) => {
  // const fields = tableColumn?.map(v => v.prop)
  const fields = tableColumn && tableColumn.map(v => v.prop)
  const array = []
  // if (!tableData?.length || !tableColumn?.length || !mergeCols?.length) return
  if (!tableData.length || !tableColumn.length || !mergeCols.length) return
  // 倒叙遍历行（方便统计合并列单元格数至最上方，避免表格塌陷）
  for (let row = tableData.length - 1; row >= 0; row--) {
    array[row] = []
    for (let col = 0; col < fields.length; col++) {
       // 1.最后一行单元格不合并（初始无可对比数据）
       // 2.不在指定列（mergeCols）的单元格不合并
       // 3.空值不合并
      if (row === tableData.length - 1 || !mergeCols.includes(fields[col]) || !tableData[row][fields[col]]) {
        array[row][col] = [1, 1]
        continue
      }
      // 4.数据相同但所属父级不一致的单元格不合并
      const parentFields = mergeCols.slice(0, col) // 在指定合并列中找出所有父级
      // if (mergeCols.includes(fields[col]) && parentFields?.includes(fields[col - 1])) {
      if (mergeCols.includes(fields[col]) && parentFields.includes(fields[col - 1])) {
        const currentParents = parentFields.map(field => tableData[row][field]) // 当前单元格所有父级
        const nextRowParents = parentFields.map(field => tableData[row + 1][field]) // 下一行单元格所有父级
        // if (currentParents?.toString() !== nextRowParents?.toString()) {
        if (currentParents.toString() !== nextRowParents.toString()) {
          array[row][col] = [1, 1]
          continue
        }
      }
      // 5.合并相同数据的单元格
      if (tableData[row][fields[col]] === tableData[row + 1][fields[col]]) {
        const beforeCell = array[row + 1][col]
        array[row][col] = [1 + beforeCell[0], 1]
        beforeCell[0] = 0
        beforeCell[1] = 0
      } else {
        array[row][col] = [1, 1] // 否则不合并
      }
    }
  }
  // console.log(array, 'array')
  return array
}


/**
     * 递归查找满足条件的对象
     * arr 需要遍历的数组
     * predicate 制定遍历规则的函数，查询条件
     * childText 定义子集的字段名，默认“children” 
     * */ 
export const findObjectInArray = (arr, predicate, childText = 'children') =>{
  for (let i = 0; i < arr.length; i++) {
    if (predicate(arr[i])) {
      return arr[i];
    }
    if (Array.isArray(arr[i][childText])) {
      const found = findObjectInArray(arr[i][childText], predicate);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

/**
     * 数值 千分位
     * num 需要处理的数值
     * */ 
export const formatThousandsNum = (num) => {
  if(num) {
    var parts = num.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    console.log('this.init();',parts.join(".") )
    return parts.join(".");
  } else {
    return ''
  }
  
}
 
// e.g. 例如
// var amount = 1234567.89;
// var formattedAmount = formatThousandsNum(amount);
// console.log(formattedAmount);
// 输出：1,234,567.89









