/*
 * 公共-API
 */

import request from '@/utils/request';

//获取预上传链接-api
export function putPresignedUrlApi(params) {
    return request({
        url: '/api/interlib-sys/sys/file/signBucket',
        method: 'GET',
        params
    });
}

//contactUs接口
export function contactUsApi(data) {
    return request({
      url: '/api/interlib-content/contactUs',
      method: 'POST',
      data
    });
}

//历史记录接口
export function historyApi() {
    return request({
      url: '/api/interlib-content/content/history',
      method: 'POST',
    });
}

//联系我们(片单时长数据）- api
export function contactUsSumUrlApi() {
    return request({
        url: '/api/interlib-content/contactUs/sum',
        method: 'GET'
    });
}

//申请使用接口
export function tryForFreeApi(data) {
    return request({
      url: '/api/interlib-sys/trial_apply/submit_apply',
      method: 'POST',
      data
    });
}

//获取图片地址
export function getImgUrl(data) {
  return request({
    url:    `/api/publish/business/getImgUrl/${data}`,
    method: 'GET',
  });
}
