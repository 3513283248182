/*
 * shopIndex API  店铺首页
 */

import request from '@/utils/request';

//banner接口
export function getBannerDataApi(params) {
    return request({
      url: `/api/interlib-content/shop/banner_list`,
      method: 'GET',
      params
    });
}

//获取店铺首页theme合集list
export function getSubjectListApi(params) {
  return request({
      url: '/api/interlib-content/shop/subject_list',
      method: 'GET',
      params
  });
}

//获取接待大厅list数据
export function getSaloonListDataApi(data) {
  return request({
      url: '/api/interlib-content/shop/subject_film_list',
      method: 'POST',
      data
  });
}

//获取接待大厅筛选条件areaData数据
export function getSaloonAreaDataApi(params) {
  return request({
      url: '/api/interlib-content/shop/region_list',
      method: 'GET',
      params
  });
}

//获取VIP大厅所需权限数据
export function getVipPowerApi(params) {
  return request({
      url: '/api/interlib-content/shop/is_vip',
      method: 'GET',
      params
  });
}

//获取店铺接待室 申请VIP
export function getApplyVipApi(params) {
  return request({
      url: '/api/interlib-content/shop/apply_vip',
      method: 'GET',
      params
  });
}

//获取店铺信息数据
export function getShopDataApi(params) {
  return request({
      url: '/api/interlib-content/shop/info',
      method: 'GET',
      params
  });
}

//获取店铺详情 留言提交接口
export function handleMessageSubmitApi(params) {
  return request({
      url: '/api/interlib-content/shop/message',
      method: 'GET',
      params
  });
}

//业务机会-片单图文信息
export function getFilmImgTextInfo(data) {
  return request({
    url: '/api/interlib-content/film_list/filmImgTextInfo',
    method: 'POST',
    data: data,
  });
}

//业务机会-提交片单图文信息
export function saveMailTemplate(data) {
  return request({
    url: '/api/interlib-content/film_list/saveMailTemplate',
    method: 'POST',
    data: data,
  });
}

//我的客户-列表-编辑-联系人列表-api
export function myCustomerListEditContactsListApi(data) {
  return request({
    url: '/api/interlib-content/film_list/contactsList',
    method: 'POST',
    data: data,
  });
}

/**
 * 我的合集-同步项目到业务机会
 * filmIdList	片单图文的包含项目
 * id 业务机会id
 * */
export function movieListSyncProjects(data) {
  return request({
    url: '/api/interlib-content/film_list/saveToBusiness',
    method: 'POST',
    data,
  });
}

//我的客户-添加联系人-api
export function myCustomerAddContactsApi(data) {
  return request({
    url: '/api/publish/customer_contacts/submit',
    method: 'POST',
    data: data,
  });
}


//我的客户-添加联系人-详情-api
export function customerContactsDetailApi(data) {
  return request({
    url: `/api/publish/customer_contacts/info/${data}`,
    method: 'GET',
  });
}
//业务机会-添加-api
export function businessOpportunityAddApi(data) {
  return request({
    url: '/api/interlib-content/film_list/createBusiness',
    method: 'POST',
    data: data,
  });
}

//业务机会-详情-api
export function businessOpportunityDetailApi(data) {
  return request({
    url: `/api/publish/business/info/${data}`,
    method: 'GET',
  });
}

//推荐项目-列表-api
export function recommendFilmListApi(data) {
  return request({
    url: '/api/interlib-content/film_list/bmsFilmList',
    method: 'POST',
    data: data,
  });
}

//地区列表-api
export function areaListApi(data) {
  return request({
    url: '/api/interlib-content/film_list/areaList',
    method: 'POST',
    data: data
  });
}
export function customerAreaListApi(data) {
  return request({
    url: '/api/interlib-content/film_list/customerAreaList',
    method: 'POST',
    data: data
  });
}

//我的客户-列表-客户分级-api
export function myCustomerListGradeApi(data) {
  return request({
    url: '/api/interlib-content/film_list/customerGradeList',
    method: 'POST',
    data: data,
  });
}

//我的客户-列表-api
export function myCustomerListApi(data) {
  return request({
    // url: 'myCustomerList',//Mock
    url: '/api/interlib-content/film_list/customerList',
    method: 'POST',
    data: data,
  });
}

//业务机会-列表-api
export function businessOpportunityListApi(data) {
  return request({
    url: '/api/interlib-content/film_list/businessList',
    method: 'POST',
    data: data,
  });
}

