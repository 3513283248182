<!-- 登录弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="isLoginDialogVisible">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>

        <img class="pop-title-img"
             src="@/assets/home/interlib-logo.png"
             alt="" />

        <span class="pop-tips"> {{ loginTips }}</span>

        <CustomLoginInput class="item-border"
                          v-model="inputEmail"
                          value=""
                          :placeholder="$t('login_account')"
                          @input="handleEmailInput" />
        <CustomLoginInputPassword class="item-border password"
                                  v-model="inputPassword"
                                  value=""
                                  :placeholder="$t('login_password')"
                                  type="password"
                                  @input="handlePasswordInput" />
        <div class="password-container">
          <div class="remember-password"
               @click="rememberClick()">
            <img v-show="this.rememberPassword == true"
                 :src="require('@/assets/login/check-select.png')"
                 alt="" />
            <img v-show="this.rememberPassword == false"
                 :src="require('@/assets/login/check-normal.png')"
                 alt="" />
            <span>{{$t('login_remember_password')}}</span>
          </div>
          <div class="forget-password"
               @click="forgetPasswordClick">
            <span>{{$t('login_forget_password')}}</span>
            <img src="@/assets/login/arrow.png"
                 alt="" />
          </div>
        </div>
        <!-- 勾选隐私政策 合适版本发布 -->
        <!-- <div class="password-container">
          <div class="remember-password"
               @click="isAgree = !isAgree">
            <img v-show="isAgree"
                 :src="require('@/assets/login/check-select.png')"
                 alt="" />
            <img v-show="!isAgree"
                 :src="require('@/assets/login/check-normal.png')"
                 alt="" />
            <span>{{$t('common_agree_text')}}<span style="color: #fff;">{{$t('common_privacy_text')}}</span></span>
          </div>
        </div> -->
        <div class="makesure"
             @click="loginClick">{{ $t('login_log_in') }}</div>

        <div class="sign-up-container">
          <span class="no-account">{{ $t('login_no_account') }}</span>
          <span class="sign-up"
                @click="signUpClick">{{$t('login_sign_up')}}</span>
        </div>
      </div>
    </div>
    <ResetPasswordPop ref="resetPasswordPop" />
  </div>
</template>

<script>
import CustomLoginInput from '@/components/CustomLoginInput.vue'
import CustomLoginInputPassword from '@/components/CustomLoginInputPassword.vue'
import ResetPasswordPop from '@/views/login/ResetPasswordPop.vue'
import { recordUserRegisterActionApi } from '@/api/userLogApi.js'

// 导入登录接口
import { loginApi, userInfoApi } from '@/api/userApi.js'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    CustomLoginInput,
    CustomLoginInputPassword,
    ResetPasswordPop
  },
  computed: {
    ...mapState('dialog', ['isLoginDialogVisible'])
  },
  watch: {
    // 监听 isLoginDialogVisible 变化，在变化时执行相应的逻辑
    isLoginDialogVisible (newValue) {
      if (newValue) {
        // 弹窗显示的逻辑
        this.reloadLocationData()
        this.getRecordUserRegisterActionData('2')
      }
    }
  },
  data () {
    return {
      inputEmail: '',
      inputPassword: '',
      rememberPassword: false, // 是否记住密码
      isAgree: false, // 是否勾选隐私政策
      loginTips: ''
    }
  },

  created () {
    this.reloadLocationData()
  },
  methods: {
    ...mapActions('dialog', [
      'showLoginDialog',
      'hideLoginDialog',
      'showForgetPasswordDialog',
      'hideForgetPasswordDialog',
      'showSignupDialog',
      'hideSignupDialog'
    ]),
    ...mapActions('auth', ['login', 'logout']),

    close () {
      this.hideLoginDialog()
      this.handleRemove()
    },
    reloadLocationData () {
      // 检查本地存储中的账号和密码
      const username = localStorage.getItem('interlibEmail')
      const password = localStorage.getItem('interlibPassword')

      console.log(`interlibEmail + ${username}`)
      console.log(`interlibPassword + ${password}`)

      if (username && password) {
        // 如果本地存储中存在账号和密码，则将其填充到输入框中，并将 rememberPassword 设置为 true
        this.inputEmail = username
        this.inputPassword = password
        this.rememberPassword = true
      }
    },
    //忘记密码
    forgetPasswordClick () {
      this.hideLoginDialog()
      this.showForgetPasswordDialog()
    },
    //注册
    signUpClick () {
      this.hideLoginDialog()
      this.showSignupDialog()
    },

    handleRemove () {
      if (!this.rememberPassword) {
        this.inputEmail = ''
        this.inputPassword = ''
      }
    },
    //登录
    loginClick () {
      // if(!this.isAgree) {
      //   this.loginTips = this.$t('tips_input_privacy');
      //   setTimeout(() => {
      //     this.loginTips = ''
      //   }, 4000)
      //   return false;
      // }
      if (!this.inputEmail) {
        this.loginTips = this.$t('tips_input_email');

        setTimeout(() => {
          this.loginTips = ''
        }, 4000)
        return
      }
      if (!this.inputPassword) {
        this.loginTips = this.$t('tips_input_password');

        setTimeout(() => {
          this.loginTips = ''
        }, 4000)
        return
      }

      // 根据记住密码的状态进行数据存储或清除
      if (this.rememberPassword) {
        // 保存账号和密码到本地存储
        localStorage.setItem('interlibEmail', this.inputEmail)
        localStorage.setItem('interlibPassword', this.inputPassword)
      } else {
        // 清除本地存储中的账号和密码
        localStorage.removeItem('interlibEmail')
        localStorage.removeItem('interlibPassword')
      }

      const that = this
      const data = {
        account: this.inputEmail,
        password: this.inputPassword
      }
      console.log(data)

      this.getRecordUserRegisterActionData('-1')

      // 发送登录请求
      loginApi(data)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          console.log(`token + ${res.data.data.token}`)
          if (res.data.code === 200) {
            console.log('es.data.code === 200')
            // 在某个组件中或其他地方调用
            this.$store
              .dispatch('auth/saveToken', res)
              .then(() => {
                // 成功保存 Token 后的操作
                this.getUserInfoData()
              })
              .catch((error) => {
                // 处理错误
                this.loginTips = error.data.message
                setTimeout(() => {
                  this.loginTips = ''
                }, 4000)
              })
          }
        })
        .catch((error) => {
          console.error(error)
          this.loginTips = error.data.message
          setTimeout(() => {
            this.loginTips = ''
          }, 4000)
        })
    },

    //获取用户信息
    getUserInfoData () {
      userInfoApi()
        .then((res) => {
          if (res.data.code === 200) {
            // 将用户信息写入VUEX
            this.$store
              .dispatch('auth/saveUserInfo', res)
              .then(() => {
                console.log('将用户信息写入VUEX')
                this.handleLogin()
              })
              .catch((error) => {
                console.log('将用户信息写入VUEX失败')
              })
          }
        })
        .catch((error) => {
          console.error(error)
          this.loginTips = error.data.message
          setTimeout(() => {
            this.loginTips = ''
          }, 4000)
        })
    },

    handleLogin () {
      this.login()
        .then(() => {
          this.hideLoginDialog()
          window.location.reload()
        })
        .catch((error) => {
          console.error('登录失败:', error)
        })
    },
    handleEmailInput (value) {
      this.inputEmail = value
    },
    handlePasswordInput (value) {
      this.inputPassword = value
    },
    rememberClick () {
      // 切换是否记住密码的状态
      this.rememberPassword = !this.rememberPassword
    },
    //记录用户注册行为日志
    getRecordUserRegisterActionData (actionType) {
      recordUserRegisterActionApi(actionType)
        .then(async (res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
  font-size: 16px;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 622px;
  height: 434px;
  /* height: 474px; // 含有隐私政策的高度，合适版本发布 */
  /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 620px;
  height: 432px;
  /* height: 472px; // 含有隐私政策的高度， 合适版本发布 */
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title-img {
  margin-top: 10px;
  width: 190px;
  height: auto;
}

.pop-tips {
  margin-top: 20px;
  color: #ff036a;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  height: 12px;
}

.item-border {
  margin-top: 15px;
  width: 70%;
}

.item-border.password {
  margin-top: 20px;
}

.password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}

.remember-password {
  display: flex;
  flex-direction: row;
  justify-content: left;
  cursor: pointer;
}

.remember-password img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.remember-password span {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.forget-password {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.forget-password img {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-top: 3px;
}

.forget-password span {
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.makesure {
  margin-top: 30px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  padding: 8px 80px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.sign-up-container {
  margin-top: 25px;
}

.no-account {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  margin-right: 19px;
}

.sign-up {
  color: #1cc405;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

  <style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 482px;
    height: 284px;
    /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
  }

  .pop-dialog {
    width: 480px;
    height: 282px;
    border-radius: 29px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 20px;
    cursor: pointer;
  }

  .pop-title-img {
    margin-top: -10px;
    width: 120px;
    height: auto;
  }

  .pop-tips {
    margin-top: 5px;
    color: #ff036a;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    height: 12px;
  }

  .item-border {
    margin-top: 10px;
    width: 70%;
  }

  .item-border.password {
    margin-top: 15px;
  }

  .password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin-top: 15px;
  }

  .remember-password {
    display: flex;
    flex-direction: row;
    justify-content: left;
    cursor: pointer;
  }

  .remember-password img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .remember-password span {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }

  .forget-password {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    cursor: pointer;
  }

  .forget-password img {
    width: 7px;
    height: 10px;
    margin-left: 5px;
    margin-right: 3px;
    margin-top: -3px;
  }

  .forget-password span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin-top: -5px;
  }

  .makesure {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    padding: 5px 50px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
  }

  .sign-up-container {
    margin-top: 10px;
  }

  .no-account {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin-right: 19px;
  }

  .sign-up {
    color: #1cc405;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>